import { tiersRaw } from "../../helpers/index.js";
import { customAlphabet } from "nanoid/non-secure";

const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);

var userLang = "";

try {
  userLang = navigator.language || navigator.userLanguage;
} catch (e) {
  console.log("no navigator");
}

var langKey = "de";
if (userLang?.length > 0) {
  langKey = userLang.split("-")[0];
}
export const initialStateUser = {
  order_mode: "",
  user_email: "",
  uid: "",
  vid: nanoid(10),
  studienart: "",
  modul: "",
  studiengang: "",
  vorname: "",
  universitaet: "",
  payment_process: "not_started",
  product: JSON.parse(JSON.stringify(tiersRaw[1])),
  final_price: 0,
  final_coupon_price: null,
  utm: {},
  lng: langKey,
};

export default function userReducer(state = initialStateUser, action) {
  switch (action.type) {
    case "RESET_USER":
      return {
        ...state,
        modul: initialStateUser["modul"],
        uid: initialStateUser["uid"],
        payment_process: initialStateUser["payment_process"],
        product: initialStateUser["product"],
        final_price: initialStateUser["final_price"],
        final_coupon_price: initialStateUser["final_coupon_price"],
        order_mode: initialStateUser["order_mode"],
      };
    case "SYNC_USER":
      return action.payload;
    case "SET_LNG":
      return { ...state, lng: action.payload };

    case "SET_ORDER_MODE":
      return { ...state, order_mode: action.payload };
    case "SET_UTM":
      return { ...state, utm: action.payload };
    case "SET_VID":
      return { ...state, vid: action.payload };
    case "SET_USER_EMAIL":
      return { ...state, user_email: action.payload };
    case "SET_USER_VORNAME":
      return { ...state, vorname: action.payload };
    case "SET_PAYMENT_PROCESS":
      return { ...state, payment_process: action.payload };
    case "SET_UID": {
      return { ...state, uid: action.payload };
    }
    case "SET_UNIVERSITAET": {
      return { ...state, universitaet: action.payload };
    }
    case "SET_STUDIENART": {
      return { ...state, studienart: action.payload };
    }
    case "SET_STUDIENGANG": {
      return { ...state, studiengang: action.payload };
    }
    case "SET_MODUL": {
      return { ...state, modul: action.payload };
    }
    case "SET_SETSELECTED": {
      return { ...state, product: action.payload };
    }
    case "SET_FINAL_PRICE": {
      return { ...state, final_price: action.payload };
    }
    case "SET_FINAL_COUPON_PRICE": {
      return { ...state, final_coupon_price: action.payload };
    }
    default:
      return state;
  }
}
