
import * as Sentry from "@sentry/gatsby";

if( process.env.GATSBY_MY_ENV === "production") {
Sentry.init({
  dsn: "https://7166eab414f69db7eccfc8695c70a57d@o559460.ingest.sentry.io/4506408241397760",
  integrations: [new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

}
