import React from "react"
import { Provider } from "react-redux"
import { Helmet } from "react-helmet";
import { PersistGate } from 'redux-persist/integration/react';
import { persistReducer, persistStore } from 'redux-persist';
import storeF, { store } from './createStore';
import { useEffect } from "react";

import { useSelector, useDispatch } from 'react-redux';
import { PostHogProvider } from 'posthog-js/react'


/*
ReactGA.initialize([
  {
    trackingId: "G-NW09G6PYHL",
 //   gaOptions: {...}, // optional
 //   gtagOptions: {...}, // optional
  },
]);

*/
const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  capture_pageview:false
}

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  console.log("store", store)
  const persistor = persistStore(store)
  console.log("persistor", persistor)

  const Context = React.createContext();
  return (

    <>
      <Helmet>
        <meta charSet="utf-8" />






        {
          /*
        
              <script src="https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/build/pdf.min.js"></script>
                <script src="https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/build/pdf.worker.entry.js"></script>
        
                
                <link href="https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/web/pdf_viewer.min.css" rel="stylesheet"></link>
        
                
         <link
                  rel="preload"
                  href="/fonts/Test Untitled Sans/test-untitled-sans-regular.woff2"
                  as="font"
                  type="font/woff2"
                  crossOrigin="anonymous"
                  key="cs-font-regular"
                />
          */
        }

      </Helmet>


      <Provider store={store} context={Context}>
        <PersistGate loading={null} persistor={persistor}>
          <PostHogProvider
            apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
            options={options}
          >

            {element}


          </PostHogProvider>
        </PersistGate>
      </Provider> </>)

}