import pdfReducer from "./pdf"
import userReducer from "./user"
import { combineReducers } from 'redux'
import app_stateReducer from "./app_state"



export default combineReducers({
    // Define a top-level state field named `todos`, handled by `todosReducer`
   
    pdf:pdfReducer,
    user:userReducer,
    app_state:app_stateReducer,
  })