import wordsCount from "words-count";
import posthog from "posthog-js";
import * as Sentry from "@sentry/browser";
import { gtag, install } from "ga-gtag";
import { customAlphabet} from "nanoid/non-secure";
import { saveAs } from "file-saver";
import { useLocation } from "@reach/router"; 

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 10)
  



export const useGetParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let paramsJson = {};
  searchParams.forEach((value, key) => {
    try {
      paramsJson[key] = JSON.parse(value);
    } catch (e) {
      paramsJson[key] = value;
    }
  });
  return paramsJson;
};




export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
//import ReactPixel from './fb_pixel';
export const validateEmail = (email) => {
  // Add your email validation logic here, for demonstration using simple regex
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

export const trackPurchase = (productName, productValue, uid) => {
  //ReactPixel.pageView();
  var m = ""
  try{
    var r = localStorage.getItem("persist:root");
    console.log("GTM r",r)
    var j = JSON.parse(r)
    var user = JSON.parse(j["user"])
    m =  user["user_email"]
  }catch(e){}

  gtag("event", "purchase", {
    order_id: uid,
   // event_id: nanoid(10),
    category: "Ecommerce",
    action: "purchase",
    transaction_id: uid,
    label: productName,
    currency: "EUR",
    value: productValue,
    items: [
      {
        item_id: productName, // Item ID.
        item_name: productName,
        price: productValue,
        quantity: 1,
      },
    ],
    ue:m
  });

  var d = {
    action: "Cs_" + "purchase",
    product: productName,
    value: productValue,
  };
 // posthog.capture("cs_event", d);


 
};

export const track_study_data = (uid, study_data) => {

  console.log("track_study_data", uid, study_data);


  Sentry.setUser(study_data);
  trackEvent("capture_study_data", uid, study_data);
};

export const track_user_identity = (email, first_name, uid) => {
  var klaviyo = window?.klaviyo || null;

  var Klaviyo = {
    $email: email,
  };

  var meta = { em: email };
  var sentryd = { email: email };
  var ph = { email: email };
  if (first_name) {
    if (first_name.length > 1) {
      Klaviyo["$first_name"] = first_name;
      meta = { ...meta, fn: first_name };
      sentryd = { ...sentryd, first_name: first_name };
      ph = { ...ph, first_name: first_name };
    }
  }

  // Identifying a person and tracking Klaviyo properties.
  console.log("klaviyo", klaviyo);
  var klaviyo = window?.klaviyo || null;

  if (klaviyo) {
    try{
      klaviyo.identify(Klaviyo);

    }catch(e){
    console.log("klaviyo error", e);
    }
  }
  trackEvent("identify", uid, meta);
  var resSentry = Sentry.setUser(sentryd);
  //trackEvent("Cs_capture_user_data", uid, ph);
  /*
  var resPosthog = posthog.capture("cs_event", {
    action: "Cs_capture_user_data",
    $set: ph,
    category: "input",
    uid: uid,
  });
  */
  console.log("track_user_identityFin",  resSentry);
};

export const faecher = [
  {
    "name": "Einführung in die BWL",
    "similarity": 100
  },
  {
    "name": "BWL 1 - Unternehmensorganisation",
    "similarity": 100
  },
  {
    "name": "Geldtheorie",
    "similarity": 80
  },{
    "name": "Versicherungswesen",
    "similarity": 80
  },
  {
    "name": 'IT-Project Engineering und Management',
    "similarity": 30
  },
  

  {
    "name": 'Grundlagen und Formen der ästhetischen Bildung',
    "similarity": 30
  },
 
  {
    "name": 'Grundlagen der Bauphysik',
    "similarity": 10
  },
  {
    "name": 'Einführung in die Vergleichende Kultur u. Religionswissenschaft',
    "similarity": 10
  },
 
]

export const getGreeting = ()=> {
  const hour = new Date().getHours();
  if (hour < 12) {
      return 'Guten Morgen';
  } else if (hour < 17) {
      return 'Guten Tag';
  } else {
      return 'Guten Abend';
  }
}
export const trackPageview = (path, title,uid) => {
  //ReactPixel.pageView();
  gtag("event", "page_view", {
    page: path,
    title: title,
    order_id: uid,
   // event_id: nanoid(10),
  });
};

export const trackEvent = (action, uid, data) => {
  var m = ""
  try{
    var r = localStorage.getItem("persist:root");
    console.log("GTM r",r)
    var j = JSON.parse(r)
    var user = JSON.parse(j["user"])
    m =  user["user_email"]
  }catch(e){}
  var d = {
    action: "Cs_" + action,
    category: "click",
    uid: uid,
    order_id: uid,

    ue:m

  }; 

  if (data) {
    if (Object.keys(data).length > 0) {
      d = { ...d, ...data };
    }
  }

  for (let key in d) {
    if (d[key] === "") {
      delete d[key];
    }
  }
  console.log("--> trackEvent", d);
  //GOOGLE
//  d["event_id"] = nanoid(10);

  //ReactGA.event(d);
  gtag("event", "Cs_" + action, d);

  
  
};

export const base64ToUint8Array = (base64) => {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

export function _arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function base64ToBlob(base64, contentType) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export function setArrayValues(array, lowerLimit, upperLimit) {
  for (let i = 0; i < array.length; i++) {
    //i = i+1
    if (i >= lowerLimit - 1 && i <= upperLimit - 1) {
      array[i] = true;
    } else {
      array[i] = false;
    }
  }
  return array;
}

export function wordCounter(text) {
  console.log(text);
  return wordsCount(text); //text.split(/\s/).filter(Boolean).length;
}

export function uploadpdf(uid, base64str, file, callback) {
  console.log("IS IN UPLOADPDF", uid, base64str, file);
  fetch("https://api.clearscribe.ai/cs_pdf_uploader", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      uid: uid,
      // pdf_b64: base64str,
      file: file,
    }),
  })
    .then((response) => response.json())
    .then(async (presignedUrl) => {
      console.log("upload url", presignedUrl);
      const response = await fetch(presignedUrl, {
        method: "PUT",
        mode: "cors",
        body: base64ToBlob(base64str, "application/pdf"),
      });

      callback(response.status);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function initprocess(uid, callback) {
  const d = new Date();
  let datestring = d.toISOString();
  fetch("https://api.clearscribe.ai/cs_init_process", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "0h0a5xbIwt33UNqvw0U07984PKhnKf28Rkvq5Qk2",
    },
    body: JSON.stringify({
      //     "email": user_email,
      uid: uid,
      upload_time: datestring,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      callback();
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function updateprocess(uid, data, callback) {
  console.log("updateprocess", uid, data);
  fetch("https://api.clearscribe.ai/cs_update_process", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "0h0a5xbIwt33UNqvw0U07984PKhnKf28Rkvq5Qk2",
    },
    body: JSON.stringify({
      data: data,
      uid: uid,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      callback();
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function getOrderStatus(uid, callback) {
  console.log("cs_get_order_status", uid);
  fetch("https://api.clearscribe.ai/cs_get_order_status", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      uid: uid,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
export function isLocalhost() {
  console.log("in isLocalhost");
  if (typeof window !== "undefined") {
    return (
      window?.location?.hostname === "localhost" ||
      window?.location?.hostname === "127.0.0.1"
    );
  }
  return false; // Return false or handle differently if not in browser context
}

export const tiersRaw = [
  {
    name: "clearscribe GO",
    product:"clearscribe_go",
    id: 0,
    href: "#",
    featured: false,
    description: "Unser puristisches Paket für den einfachen Einstieg.",
    price: 1.99,
    max_pages:50,
    send_delay:45,
    features: [
      "clearscribe Lerninhalte",
      "clearscribe Viewer",
      "Empfang innerhalb 45 Minuten",
    //  "Werbung",
      "50 Vorlesungsfolien inklusive*",
    ],
  },
  {
    name: "clearscribe PLUS",
    product:"clearscribe_plus",
    id: 1,
    href: "#",
    featured: true,
    max_pages:50,
    send_delay:15,
    description: "Unser beliebtestes Paket für tiefgehendes Verständnis.",
    price: 2.99,
    features: [
      "clearscribe Lerninhalte",
      "clearscribe Mindmap",
      "clearscribe Viewer",
      "Empfang innerhalb 15 Minuten",
      "Bilder von Vorlesungsfolien für mehr Kontext",
      "50 Vorlesungsfolien inklusive*",
    ],
  },
  {
    name: "clearscribe PREMIUM",
    product:"clearscribe_premium",
    id: 2,
    href: "#",
    max_pages:80,
    send_delay:15,
    featured: false,
    description: "Das beste und umfangreichste was clearscribe zu bieten hat.",
    price: 4.49,
    features: [
      "clearscribe Lerninhalte",
      "clearscribe Mindmap",
      "clearscribe Viewer",
      "Empfang innerhalb 15 Minuten",
      "Bilder von Vorlesungsfolien für mehr Kontext",
      "80 Vorlesungsfolien inklusive*",
    //  "clearscribe Glossar",
    //  "optionales ungebrandetes PDF",
    ],
  },
];
export const tiersRaw_Affiliate = [
  {
    name: "clearscribe GO",
    product:"clearscribe_go",
    id: 0,
    href: "#",
    featured: false,
    description: "Unser puristisches Paket für den einfachen Einstieg.",
    price: 1.99,
    max_pages:50,
    send_delay:45,
    features: [
      "clearscribe Lerninhalte",
      "clearscribe Viewer",
      "Empfang innerhalb 45 Minuten",
    //  "Werbung",
      "50 Vorlesungsfolien inklusive",
    ],
  },
  {
    name: "clearscribe PLUS",
    product:"clearscribe_plus",
    id: 1,
    href: "#",
    featured: true,
    max_pages:50,
    send_delay:15,
    description: "Unser beliebtestes Paket für tiefgehendes Verständnis.",
    price: 2.99,
    features: [
      "clearscribe Lerninhalte",
      "clearscribe Mindmap",
      "clearscribe Viewer",
      "Empfang innerhalb 15 Minuten",
      "Bilder von Vorlesungsfolien für mehr Kontext",
      "50 Vorlesungsfolien inklusive",
    ],
  },
  {
    name: "clearscribe PREMIUM",
    product:"clearscribe_premium",
    id: 2,
    href: "#",
    max_pages:80,
    send_delay:15,
    featured: false,
    description: "Das beste und umfangreichste was clearscribe zu bieten hat.",
    price: 4.49,
    features: [
      "clearscribe Lerninhalte",
      "clearscribe Mindmap",
      "clearscribe Viewer",
      "Empfang innerhalb 15 Minuten",
      "Bilder von Vorlesungsfolien für mehr Kontext",
      "80 Vorlesungsfolien inklusive",
    //  "clearscribe Glossar",
    //  "optionales ungebrandetes PDF",
    ],
  },
  {
    name: "clearscribe Karteikarten",
    product:"clearscribe_cards",
    id: 3,
    href: "#",
    max_pages:50,
    send_delay:15,
    featured: false,
    description: "Das beste und umfangreichste was clearscribe zu bieten hat.",
    price: 0.99,
    features: [
      "Individuelle Karteikarten",
      "Empfang innerhalb 15 Minuten",
      "Herunterladen als Word, CSV oder Anki datei",
      "50 Vorlesungsfolien inklusive",
    //  "clearscribe Glossar",
    //  "optionales ungebrandetes PDF",
    ],
  },
];


export async function fetchCsvAndConvertToJson(TYPE,cb) {
  // FAQ Default
  var url = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQ-F7kE4xMHCD5ommZZs_CtJNe2MnF_DqMUA2V0RGb-qoLVL2q8azevFbTNM4FUrRLxLXk0--0Osd0v/pub?gid=0&single=true&output=tsv"
  if(TYPE === "FAQ"){
    url = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQ-F7kE4xMHCD5ommZZs_CtJNe2MnF_DqMUA2V0RGb-qoLVL2q8azevFbTNM4FUrRLxLXk0--0Osd0v/pub?gid=0&single=true&output=tsv"
  }

  try {
      const response = await fetch(url);
      const csvText = await response.text();

      const csvToJson = (csv) => {
          const lines = csv.split("\n");
          const headers = lines[0].split("\t");
          return lines.slice(1).map(line => {
              const data = line.split("\t");
              return headers.reduce((obj, nextKey, index) => {
                  obj[nextKey.trim()] = data[index].trim();
                  return obj;
              }, {});
          });
      };
      console.log("csvText", csvText)
      var jsonData = csvToJson(csvText);
      console.log("jsonData", jsonData)
      jsonData.sort((a, b) => {
        if (a.Type < b.Type) return -1;
        if (a.Type > b.Type) return 1;
        if (a.Frage < b.Frage) return -1;
        if (a.Frage > b.Frage) return 1;
        return 0;
    });
      cb(jsonData);
  } catch (error) {
      console.error("Error fetching or converting CSV:", error);
      cb([]);
  }
}

export const fetchAndDownloadPDF = async (order_status,uid) => {
  console.log("fetchAndDownloadPDF1", order_status);

  trackEvent("status_load_final_pdf", uid);
  console.log("fetchAndDownloadPDF", order_status);
  try {
    // Step 1: Fetch Data from API
    var presignedUrl =
      "https://clearscribe-pdf-results.s3.eu-central-1.amazonaws.com/" +
      uid +
      ".pdf";
    console.log("presignedUrl 1", presignedUrl);

    const pdfResponse = await fetch(presignedUrl, {
      method: "GET",
      //mode: 'no-cors'
    });
    console.log("presignedUrl 2");

    const pdfBlob = new Blob([await pdfResponse.arrayBuffer()], {
      type: "application/pdf",
    });

    const formatDate = () => {
      const now = new Date();
      return now.toISOString().split("T")[0];
    };

    const createFilename = (vorname, studienfach, uid) => {
      const dateStr = formatDate();
      const studienfachFormatted = studienfach.replace(/ /g, "_");
      return `${dateStr}_clearscribe_fuer_${vorname}_(${studienfachFormatted})_${uid}.pdf`;
    };

    var fname = createFilename(
      order_status?.vorname,
      order_status?.modul,
      order_status?.uid
    );

    saveAs(pdfBlob, fname);
  } catch (error) {
    console.error("Error:", error);
  }


};
 
export const download = async (t,order_status,uid) => {
  trackEvent("cards_download_" + t, uid);
  console.log("download", t);
  var durl = "https://clearscribe-cards.s3.eu-central-1.amazonaws.com/cards_" + uid + "." + t;
  
  console.log("download", t, durl);
  try {
    // Fetch Data from API
    const response = await fetch(durl, { method: "GET" });

    // Determine the MIME type based on the file type
    let mimeType = "";
    switch (t) {
      case "docx":
        mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case "csv":
        mimeType = "text/csv";
        break;
      case "apkg":
        mimeType = "application/octet-stream";
        break;
      default:
        throw new Error("Unsupported file type");
    }

    const blob = new Blob([await response.arrayBuffer()], { type: mimeType });

    // Helper function to format date
    const formatDate = () => {
      const now = new Date();
      return now.toISOString().split("T")[0];
    };

    // Create a filename based on user details and file type
    const createFilename = (vorname, studienfach, uid) => {
      const dateStr = formatDate();
      const studienfachFormatted = studienfach.replace(/ /g, "_");
      return `${dateStr}_clearscribe_fuer_${vorname}_(${studienfachFormatted})_${uid}.${t}`;
    };

    var fname = createFilename(
      order_status?.vorname,
      order_status?.modul,
      order_status?.uid
    );

    // Trigger the download
    saveAs(blob, fname);
  } catch (error) {
    console.error("Error:", error);
  }
};