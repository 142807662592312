export const initialState_app_state = {
    wizzard_step: 0,
    app_validation_reducer_0: false,
    app_validation_reducer_1: false,
    app_validation_reducer_2: true,

    app_validation_reducer_3: false,

    modal_open: false,
    modal_type: "welcome",
    break_point: "below-sm",
    warenkorb: [],
};

export default function wizzard_step_Reducer(state = initialState_app_state, action) {
    switch (action.type) {
        case 'RESET_APPSTATE':
            return initialState_app_state;
            case 'SET_WARENKORB':
                return { ...state, warenkorb: action.payload };
        case 'SET_WIZZARD_STEP':
            return { ...state, wizzard_step: action.payload };
        case 'SET_APP_VALIDATION_REDUCER_0':
            return { ...state, app_validation_reducer_0: action.payload };
        case 'SET_APP_VALIDATION_REDUCER_1':
            return { ...state, app_validation_reducer_1: action.payload };
        case 'SET_APP_VALIDATION_REDUCER_2':
            return { ...state, app_validation_reducer_2: action.payload };
        case 'SET_APP_VALIDATION_REDUCER_3':
            return { ...state, app_validation_reducer_3: action.payload };

        case 'SYNC_APPSTATE':
            return action.payload;
        case 'SET_MODAL_OPEN':
            return { ...state, modal_open: action.payload };
        case 'SET_MODAL_TYPE':
            return { ...state, modal_type: action.payload };

        case 'SET_BREAK_POINT':
            return { ...state, break_point: action.payload };
        default:
            return state;
    }
}
