exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-beispiel-js": () => import("./../../../src/pages/beispiel.js" /* webpackChunkName: "component---src-pages-beispiel-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-clearscribe-js": () => import("./../../../src/pages/clearscribe.js" /* webpackChunkName: "component---src-pages-clearscribe-js" */),
  "component---src-pages-clearscribe-methode-js": () => import("./../../../src/pages/clearscribe-methode.js" /* webpackChunkName: "component---src-pages-clearscribe-methode-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-karteikarten-js": () => import("./../../../src/pages/karteikarten.js" /* webpackChunkName: "component---src-pages-karteikarten-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-law-js": () => import("./../../../src/pages/law.js" /* webpackChunkName: "component---src-pages-law-js" */),
  "component---src-pages-medicine-js": () => import("./../../../src/pages/medicine.js" /* webpackChunkName: "component---src-pages-medicine-js" */),
  "component---src-pages-partner-werden-js": () => import("./../../../src/pages/partner-werden.js" /* webpackChunkName: "component---src-pages-partner-werden-js" */),
  "component---src-pages-preise-js": () => import("./../../../src/pages/preise.js" /* webpackChunkName: "component---src-pages-preise-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */),
  "component---src-pages-student-ambassador-js": () => import("./../../../src/pages/student-ambassador.js" /* webpackChunkName: "component---src-pages-student-ambassador-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */)
}

