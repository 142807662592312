import rootReducer from "./reducers"
import logger from 'redux-logger'
import { compose } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';


//const persistedReducer = persistReducer(persistConfig, rootReducer)
const persistedReducer = persistReducer({
  key: 'root',
  storage,
  blacklist: ['pdf',"app_state"]
}, rootReducer);
const composeEnhancers = (typeof window === 'object') ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : undefined
var preloadedState = {}


var mws = [thunk]
if (process.env.NODE_ENV !== 'production') {
  mws.push(logger)
}
const storeF = () => {
  return  configureStore({
    reducer: persistedReducer,
     preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
    composeEnhancers:composeEnhancers ? composeEnhancers() : compose,
    middleware: mws,
  },)
}

var store = storeF()
  
export { store }
export default function () { return store}



/*




import { compose, createStore } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import initialState from './initialState'
import rootReducer from './rootReducer'

const persistedReducer = persistReducer({
  key: 'root',
  storage
}, rootReducer);

const composeEnhancers = (typeof window === 'object') ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : undefined

const store = () => {
  return createStore(
    persistedReducer,
    initialState,
    composeEnhancers ? composeEnhancers() : compose
  );
}






*/