export const initialStatePDF = {
    file: null,
    file_raw: null,
    selected_pages_arr: [],
  
    extra_pages:0,
    price_extra_pages:0,
    paypal_order_obj: null,
    img_first_page:null
};

export default function pdfReducer(state = initialStatePDF, action) {
    switch (action.type) {
        case 'RESET_PDF':
            return  initialStatePDF ;
        case 'SYNC_PDF':
            return  action.payload ;

        case 'SET_PDF_FILE':
            return { ...state, file: action.payload };

            case 'SET_IMG_FIRST_PAGE':
            return { ...state, img_first_page: action.payload };
            case 'SET_PP_ORDER_OBJ':
                return { ...state, paypal_order_obj: action.payload };
        case 'SET_PDF_FILE_RAW':
            return { ...state, file_raw: action.payload };
        case 'SET_SELECTED_PAGES_ARR':
            return { ...state, selected_pages_arr: action.payload };
        case 'SET_EXTRA_PAGES':
            return { ...state, extra_pages: action.payload };
            case 'SET_PRICE_EXTRA_PAGES':
                return { ...state, price_extra_pages: action.payload };
        default:
            return state;

            
            
    }
}
